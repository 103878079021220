import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionState, ProjectSettingCategory } from './permission.models';
import { TaskPermissionChecker } from '../../tasks/_services/task-permission.service';
import { PermissionChecker } from 'src/app/shared';
import { selectProjectRoles } from '../site-management';
import { selectProjectTaskDFields } from '../dynamic-field';

export const featureKey = 'permission';
export const projectPermission: Record<number, TaskPermissionChecker> = {};
export const projectSettingPermission: Record<number, Record<string, PermissionChecker>> = {};

const selectFeature = createFeatureSelector<PermissionState>(featureKey);

const selectTask = createSelector(selectFeature, (state) => state.task);

export const selectTaskPermission = (projectId?: number) =>
  createSelector(
    selectTask,
    selectProjectRoles,
    (taskPermission, projectRoles) => {
      if (projectPermission[projectId]) {
        return projectPermission[projectId];
      }

      const roles = projectRoles[projectId];
      const permission = new TaskPermissionChecker(
        {
          editCodes: taskPermission.edit,
          editAnotherCodes: taskPermission.editAnother,
          ...taskPermission
        },
        roles
      );
      projectPermission[projectId] = permission;

      return permission;
    }
  );

export const selectProjectTaskPermission = () =>
  createSelector(
    selectTask,
    selectProjectRoles,
    (taskPermission, projectRoles) => {
      let result: {projectId: number, permission: TaskPermissionChecker}[] = [];

      for (const projectId in projectRoles) {
        const roles = projectRoles[projectId];

        result.push({
          projectId: +projectId,
          permission: new TaskPermissionChecker(
            {
              editCodes: taskPermission.edit,
              editAnotherCodes: taskPermission.editAnother,
              ...taskPermission
            },
            roles
          )
        });
      }

      return result;
    }
  );

export const selectTaskAndFieldsPermission = (projectId: number) =>
  createSelector(
    selectTaskPermission(projectId),
    selectProjectTaskDFields(projectId),
    (permission, fields) => ({ permission, fields })
  );

const selectBacklogs = createSelector(selectFeature, (state) => state.backlogs);

export const selectBacklogsPermission = (projectId: number) => createSelector(
  selectBacklogs,
  selectProjectRoles,
  ((backlogsState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: backlogsState.edit,
        viewCodes: backlogsState.view,
      },
      roles
    )
  })
);

const selectTaskFinder = createSelector(
  selectFeature,
  (state) => state.taskFinder
);

export const selectTaskFinderPermission = createSelector(
  selectTaskFinder,
  (state) =>
    new PermissionChecker({
      editCodes: state.edit,
      viewCodes: state.view
    })
);

const selectStoryline = createSelector(
  selectFeature,
  (state) => state.storyline
);

export const selectStorylinePermission = (projectId: number) => createSelector(
  selectStoryline,
  selectProjectRoles,
  selectTaskPermission(projectId),
  ((storylineState, projectRoles, taskPermission) => {
    const roles = projectRoles[projectId];
    const storylinePermission = new PermissionChecker(
      {
        editCodes: storylineState.edit,
        viewCodes: storylineState.view
      },
      roles
    );
    taskPermission.canEdit = storylinePermission.canEdit;
    taskPermission.canView = storylinePermission.canView;
    return taskPermission;
  })
);

/** Project Setting */
const selectProjectSetting = createSelector(selectFeature, state => state.projectSetting);

export const selectProjectSettingPermission = (projectId: number, category: ProjectSettingCategory) =>
  createSelector(
    selectProjectSetting,
    selectProjectRoles,
    (settingPermission, projectRoles) => {
      let setting = projectSettingPermission?.[projectId]?.[category];

      if (setting) {
        return setting;
      }

      const { 
        view: viewCodes,
        viewAnother: viewAnotherCodes = [], 
        edit: editCodes,
        editAnother: editAnotherCodes = []
      } = settingPermission[category];
      const roles = projectRoles[projectId];

      setting = new PermissionChecker(
        { viewCodes, editCodes, viewAnotherCodes, editAnotherCodes },
        roles
      );
      projectSettingPermission[projectId] = {
        ...projectSettingPermission[projectId],
        [category]: setting,
      };
      return setting;
    }
  );

/** Epic */
const selectEpic = createSelector(selectFeature, (state) => state.epic);

export const selectEpicPermission = (projectId: number) => createSelector(
  selectEpic,
  selectProjectRoles,
  selectTaskPermission(projectId),
  ((epicState, projectRoles, taskPermission) => {
    const roles = projectRoles[projectId];
    const epicPermission = new PermissionChecker(
      {
        viewCodes: epicState.view,
        editCodes: epicState.edit,
      },
      roles
    );
    taskPermission.canEdit = epicPermission.canEdit;
    taskPermission.canView = epicPermission.canView;
    return taskPermission;
  })
);

/** Task Template */
const selectTaskTemplate = createSelector(selectFeature, (state) => state.taskTemplate);

export const selectTaskTemplatePermission = (projectId: number) => createSelector(
  selectTaskTemplate,
  selectProjectRoles,
  selectTaskPermission(projectId),
  ((taskTemplateState, projectRoles, taskPermission) => {
    const roles = projectRoles[projectId];
    const taskTemplatePermission = new PermissionChecker(
      {
        viewCodes: taskTemplateState.view,
        editCodes: taskTemplateState.edit,
      },
      roles
    );
    taskPermission.canEdit = taskTemplatePermission.canEdit;
    taskPermission.canView = taskTemplatePermission.canView;
    return taskPermission;
  })
);

/** Releases */
const selectReleases = createSelector(selectFeature, (state) => state.releases);

export const selectReleasesPermission = (projectId: number) => createSelector(
  selectReleases,
  selectProjectRoles,
  ((releasesState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: releasesState.edit,
        viewCodes: releasesState.view,
      },
      roles
    )
  })
);

const selectReleaseDetails = createSelector(
  selectFeature,
  (state) => state.releaseDetails
);

export const selectReleaseDetailsPermission = (projectId: number) => createSelector(
  selectReleaseDetails,
  selectProjectRoles,
  ((releaseDetailState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: releaseDetailState.edit,
        viewCodes: releaseDetailState.view,
      },
      roles
    )
  })
);

const selectReleaseNote = createSelector(
  selectFeature,
  (state) => state.releaseNote
);

export const selectReleaseNotePermission = (projectId: number) => createSelector(
  selectReleaseNote,
  selectProjectRoles,
  ((releaseNoteState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: releaseNoteState.edit,
        viewCodes: releaseNoteState.view,
      },
      roles
    )
  })
);

/** Active Board */
const selectActiveBoard = createSelector(
  selectFeature,
  (state) => state.activeBoard
);

export const selectActiveBoardPermission = (projectId: number) => createSelector(
  selectActiveBoard,
  selectProjectRoles,
  ((activeBoardState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: activeBoardState.edit,
        viewCodes: activeBoardState.view,
      },
      roles
    )
  })
);

/** Sprint Detail */
const selectSprint = createSelector(
  selectFeature,
  (state) => state.sprint
);

export const selectSprintPermission = (projectId: number) => createSelector(
  selectSprint,
  selectProjectRoles,
  ((sprintState, projectRoles) => {
    const roles = projectRoles[projectId];
    return new PermissionChecker(
      {
        editCodes: sprintState.edit,
        viewCodes: sprintState.view,
      },
      roles
    )
  })
);

/** Status Group */
const selectStatusGroup = createSelector(
  selectFeature,
  (state) => state.statusGroup
);

export const selectStatusGroupPermission = createSelector(
  selectStatusGroup,
  (statusGroupState) =>
    new PermissionChecker({ editCodes: statusGroupState.edit })
);
