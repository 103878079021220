import { OkrType } from 'src/app/site-management/okr/_model';
import { OkrWorkspace, SelectOption, Task, TaskAttachment, TaskAttachmentType } from '..';
import { DatePeriod, OkrMyFollowUp, OkrRiskLevel, OkrObjectiveType, OkrResultType, OkrIndicator, OkrCategory } from '../_utils';
import { FilterBy, FilterItem } from './app/filter.model';
import { AttachmentType, BaseAttachment } from './attachment.model';
import { Group } from './group.model';
import { Metrics } from './metrics.model';
import { OkrKeyResult, OkrKeyResultItem } from './okr-key-result.model';
import { OkrTimeline } from './okr-timeline.model';
import { Team } from './team.model';
import { User } from './user.model';

export class OkrBase {
  id: number;
  start: number;
  current: number;
  expected: number;
  weight: number;
  objectiveType: OkrObjectiveType;
  resultType: OkrResultType;
  progress: number;
  totalValue: number;
  percentValue: number;
  justify: number;
  isEditable?: boolean;
}

export interface OkrShortItem {
  id: number;
  name: string;
  key: string;
  objectiveType: OkrObjectiveType;
}

export interface OkrItem {
  id: number;
  taskId: number;
  typeIcon: string;
  type: OkrType;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  keyResult: OkrKeyResult;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  justify: number;
  krWeight: number;
  progress: number;
  path: OkrShortItem[];
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  riskLevel: OkrRiskLevel;
  percentValue: number;
  totalValue: number;
  metric: Metrics;
  orderNumber: number;
  isEditable: boolean;
  isPopulate: boolean;
  childTimelines: OkrChildrenTimeline[];
  indicator: OkrIndicator;
  category: OkrCategory;
  itemsWeight: number;
  //
  children?: OkrItem[];
}

export class Okr extends OkrBase {
  id: number;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  progress: number;
  timeline: OkrTimeline;
  childTimelines: OkrChildrenTimeline[];
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  keyResults: OkrKeyResult[];
  tasks: OkrTask[];
  children: Okr[];
  parent: Okr;
  path: Okr[];
  krWeight: number;
  metric: Metrics;
  riskLevel: OkrRiskLevel;
  hasChild: boolean;
  percentValue: number;
  totalValue: number;
  attachments: OkrAttachment[];
  type: OkrType;
  orderNumber?: number;
  indicator?: OkrIndicator;
  workspace: OkrWorkspace;
  category: OkrCategory;
  totalCheckin?: number;
  isPopulate?: boolean;
}

export interface OkrChildrenTimeline {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  current: number;
  expected: number;
  start: number;
  weight: number;
  expectedActual: number;
  startActual: number;
  percentValue: number;
  justify: number;
  riskLevel: OkrRiskLevel;
  totalCheckin?: number;
  isLock?: boolean;
}

export enum OkrSearchType {
  kr = 'keyresult',
  okr = 'okr',
  item = 'item', // Releated to KR
  task = 'task', // Releated to OKR
}

export interface OkrSearchResponse {
  id: number;
  taskId: number;
  typeIcon: string;
  type: OkrSearchType;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  keyResult: OkrKeyResult;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  justify: number;
  krWeight: number;
  progress: number;
  path: Okr[];
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  riskLevel: OkrRiskLevel;
  totalCheckin?: number;
}

export interface OkrAssignee {
  id: number;
  fullName: string;
  avatar: string;
}

export interface OkrTask {
  id: number;
  taskId: number;
  assignee: User;
  current: number;
  percentValue: number;
  description: string;
  key: string;
  name: string;
  weight: number;
  typeIcon: string;
  metricValue: number;
  metric: Metrics;
  orderNumber?: number;
  startMetricValue: number;
  currentMetricValue: number;
  dueDate: string;
  timelineId: number;
  isEditable?: boolean;
}

export class OkrCreateRequest {
  objectiveType: OkrObjectiveType;
  name: string;
  description?: string;
  parentId?: number;
  assigneeId?: number;
  stakeholderId?: number;
  teamIds?: number[];
  groupIds?: number[];
  workspaceId?: number;
  dueDate?: string;
  resultType: string;
  // start: number;
  // current: number;
  // expected: number;
  weight?: number;
  krWeight?: number;
  keyResults?: OkrChildCreateRequest[];
  childrens?: OkrChildCreateRequest[];
  tasks?: OkrChildCreateRequest[];
  metricId?: number;
  justify?: number;
  childTimelines: OkrChildTimeline[];
  type: OkrType;
  indicator?: OkrIndicator;
  category?: OkrCategory;

  static default(): OkrCreateRequest {
    return {
      objectiveType:  null,
      name:           null,
      description:    null,
      parentId:       null,
      workspaceId:    null,
      assigneeId:     null,
      stakeholderId:  null,
      teamIds:        [],
      groupIds:       [],
      dueDate:        null,
      resultType:     null,
      type:           null,
      justify:        null,
      keyResults:     [],
      childrens:      [],
      tasks:          [],
      metricId:       null,
      childTimelines: [],
      indicator: null,
    }
  }
}

export interface OkrUpdateRequest {
  objectiveType: string;
  name: string;
  description: string;
  parentId: number;
  assigneeId: number;
  stakeholderId: number;
  teamIds: number[];
  groupIds: number[];
  workspaceId: number;
  dueDate: string;
  resultType: string;
  // start: number;
  // current: number;
  // expected: number;
  weight: number;
  krWeight: number;
  metricId?: number;
  justify: number;
  currentTimeline?: OkrUpdateTimeline;
  type: OkrType;
  timelineIds?: number[];
  startPlan?: number;
  expectedPlan?: number;
  note?: string;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  resetCheckin?: boolean;
}

export interface OkrUpdateTimeline {
  id: number;
  current: number;
  expected: number;
  justify: number;
  start: number;
}

export interface OkrLinkTaskRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
  task?: Task;
  timelineId?: number;
}

export interface OkrChildRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrKeyResultRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrChildKrCreateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrChildKrUpdateRequest {
  current: number;
  weight: number;
  assigneeId: number;
}

export interface OkrChildUpdateRequest {
  assigneeId: number;
  current: number;
  weight: number;
}

export interface OkrChildCreateRequest {
  id?: number;
  taskId?: number;
  current?: number;
  weight?: number;
  assigneeId?: number;
}

export interface OkrChildTaskUpdateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrDetailRequest {
  timelineId: number;
}

export interface OkrTreeRequest {
  timelineId: number;
  objectId: number;
  objectType: OkrObjectType;
}

export interface OkrChildTimeline {
  id: number;
  current: number;
  expected: number;
  start: number;
  weight?: number;
  justify?: number;
}

export interface OkrSearchRequest {
  startDate?: string;
  endDate?: string;
  timelineId?: number;
  searchText?: string;
  objectiveType?: OkrObjectiveType;
  userIds?: number[];
  groupIds?: number[];
  teamIds?: number[];
  userStatus?: string;
  metricFilters?: OkrMetricSearchRequest[];
  indicators?: OkrIndicator[];
}

export interface OkrMetricSearchRequest {
  metricId: number;
  type: OkrType;
}

export interface OkrTreeItem {
  type: OkrSearchType;
  row: (Okr | OkrKeyResult | OkrKeyResultItem | Task);
}


// export enum OkrTreeRowAction {
//   addOkr = 'addOkr',
//   addKr = 'addKr',
//   linkTask = 'linkTask',
//   unlinkTask = 'unlinkTask',
//   createTask = 'createTask',
//   edit = 'edit',
//   delete = 'delete',
//   deleteKr = 'deleteKr',
//   unlink = 'unlink',
// }

// export interface OkrTreeRowActionData {
//   type: OkrSearchType;
//   row: (Okr| OkrKeyResult);
//   action: OkrTreeRowAction;
// }

export class OkrFilterBy {
  followUp?: FilterItem<SelectOption<OkrMyFollowUp>>;
  objectiveType?: FilterItem<SelectOption<OkrObjectiveType>>;
  assignee?: FilterItem<User>;
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  dueDate?: FilterItem<SelectOption<DatePeriod>>;
  userStatus?: FilterItem<{ name: string, value: any }>;
  indicator?: FilterItem<{ name: string, value: OkrIndicator }>;
  // okrStatus: FilterItem<SelectOption<OkrRiskLevel>>;
}

export interface RiskLevelData {
  label: string;
  colorCode: string;
  icon: string;
  blockIcon: string;
}

export interface OkrDataChange {
  type: OkrSearchType;
  item: (Okr | OkrKeyResult);
}

export class OkrAttachment extends BaseAttachment { }

export interface OkrUpsertFile {
  file?: File;
  type?: AttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export enum OkrPatchField {
  DESCRIPTION = "description",
}

export interface OkrPatch {
  [OkrPatchField.DESCRIPTION]?: string;
}

export interface OkrReorderRequest {
  parentId?: number;
  parentType?: OkrObjectType;
  objectId?: number;
  objectType?: OkrObjectType;
  preObjectId?: number;
  preObjectType?: OkrObjectType;
  nextObjectId?: number;
  nextObjectType?: OkrObjectType;
}

export enum OkrObjectType {
  OKR = 'OKR',
  KR = 'KR',
  TASK = 'TASK',
  KR_TASK = 'KR_ITEM',
}

export interface OkrReportRequest {
  workspaceId: number;
  timelineId: number;
}

export interface OkrReport {
  metrics: OkrMetricReport[];
  types: OkrObjectReport[];
}

export interface OkrMetricReport {
  metric: Metrics,
  type: OkrType,
  totalStart: number;
  totalCurrent: number;
  totalExpected: number;
  percent: number;
  // FE
  isHide?: boolean;
}

export interface OkrObjectReport {
  objectiveType: OkrObjectiveType;
  items: OkrObjectItemReport[];
}

export interface OkrObjectItemReport {
  riskLevel: OkrRiskLevel;
  totalObjects: number;
}

export interface OkrInactiveUserReport {
  user: User;
  current: number;
  total: number;
}


export enum CheckInObjectType {
  OKR = 'OKR',
  KR = 'KEY_RESULT',
  TASK = 'TASK',
}

export enum OkrRiskLevelPercent {
  COMPLETED = 100,
  ON_TRACK = 95,
  LOW_RISK = 90,
  MEDIUM_RISK = 80,
  HIGH_RISK = 0,
}

export const OKR_CURRENT_VALUE = null;
export const OKR_START_VALUE = 0;
export const OKR_EXPECTED_100_VALUE = 100;
export const OKR_EXPECTED_0_VALUE = 0;
export const OKR_PERCENT_0_VALUE = 0;
export const OKR_JUSTIFY_VALUE = 0;
